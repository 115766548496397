<template>
<div>
    <phone_head title="物件詳細" :path="{path:'/property/top/',query: {property_id: this.$route.query.property_id}}"></phone_head>
    <p class="property_name"><img src="@\assets\home.png" alt="" class="property_name_icon">{{ receive_info.name }}</p>
    <br>
    <div>
        <h2 class="subtitle">物件概要</h2>
        <div>
            <div class="flex top_radius">
                <p class="item">物件番号</p>
                <p class="item2">{{ receive_info.number }}</p>
            </div>
            <div class="flex">
                <p class="item">分類ステータス</p>
                <p class="item2">{{ receive_info.section }}</p>
            </div>
            <div class="flex">
                <p class="item">郵便番号</p>
                <p class="item2">{{ receive_info.post_code }}</p>
            </div>
            <div class="flex">
                <p class="item">住所</p>
                <p class="item2">{{ receive_info.prefecture + receive_info.municipality + receive_info.address }}</p>
            </div>
            <div class="flex">
                <p class="item">エリア</p>
                <p class="item2">{{ receive_info.area }}</p>
            </div>
            <div class="flex">
                <p class="item">沿線</p>
                <p class="item2">{{ receive_info.line }}</p>
            </div>
            <div class="flex">
                <p class="item">最寄り駅</p>
                <p class="item2">{{ receive_info.station }}</p>
            </div>
            <div class="flex">
                <p class="item">物件担当</p>
                <p class="item2">{{ receive_info.person }}</p>
            </div>
            <div class="flex">
                <p class="item">巡回頻度</p>
                <p class="item2">{{ receive_info.frequency }}</p>
            </div>
            <div class="flex">
                <p class="item">巡回実施予定月</p>
                <p class="item2">{{ receive_info.month }}</p>
            </div>
            <div class="flex">
                <p class="item">オーナー名</p>
                <p class="item2">{{ receive_info.owner }}</p>
            </div>
            <div class="flex">
                <p class="item">下請け先</p>
                <p class="item2">{{ receive_info.subcontractor }}</p>
            </div>
            <div class="flex">
                <p class="item">オートロック番号</p>
                <p class="item2">{{ receive_info.auto_lock }}</p>
            </div>
            <div class="flex">
                <p class="item">キーボックス場所</p>
                <p class="item2">{{ receive_info.key_box }}</p>
            </div>
            <div class="flex bottom_radius">
                <p class="item">特記事項</p>
                <p id = "remarks" class="item2">{{ receive_info.remarks }}</p>
            </div>
        </div>
    </div>
    <!-- 施設編集モーダル -->
    <div class="institution_list">
        <h2>施設一覧</h2>
        <input class="edit_btn orange_btn" type="button" v-if="authority_id!=='0003'" value="編集" @click="open_modal()">
        <div id="overlay" v-show="show_modal">
            <div id="content">
                <div class="modal_title_">
                    <a @click="close_modal()"><img src="@\assets\icons8-左-50.png" alt=""></a>
                    <h2>施設編集</h2>
                </div>
                <div class="modal_items">
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.elevator">
                        <p>エレベーター</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.hallway">
                        <p>共用廊下</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.outside_stairs">
                        <p>外階段</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.planting">
                        <p>植栽</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.mechanical_parking">
                        <p>機械式駐車場</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.flat_parking">
                        <p>平置き駐車場</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.trash">
                        <p>ゴミ庫</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.trash_stocker">
                        <p>ゴミストッカー</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.entrance">
                        <p>エントランス</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.rack_b_parking">
                        <p>ラック式駐輪場</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.flat_b_parking">
                        <p>平置き駐輪場</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.flat_m_parking">
                        <p>平置きバイク置き場</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.board">
                        <p>掲示板・掲示物</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.mailbox">
                        <p>メールボックス</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.delivery_box">
                        <p>宅配ボックス</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.janitor">
                        <p>管理室</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.tools">
                        <p>清掃道具</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.receiving_tank">
                        <p>受水槽</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.cesspool">
                        <p>汚水槽</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.septic_tank">
                        <p>浄化槽</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.elevated_tank">
                        <p>高架水槽</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.booster_pump">
                        <p>増圧ポンプ</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.drainage_pump">
                        <p>排水ポンプ</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.firefight_equipment">
                        <p>消防設備</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.air_conditioner">
                        <p>空調設備</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.electrical_work">
                        <p>電気工作物</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.emergency_elevator">
                        <p>非常用エレベーター</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.disposer">
                        <p>ディスポーザー</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.distribution_board">
                        <p>分電盤</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.mdf_board">
                        <p>MDF盤</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.inner_staircase">
                        <p>内階段</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.inner_corridor">
                        <p>内廊下</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.rooftop">
                        <p>屋上</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.basement">
                        <p>地下室</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.store">
                        <p>店舗</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.office">
                        <p>事務所</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.kids_room">
                        <p>キッズルーム</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.assembly_room">
                        <p>集会室</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.locker_room">
                        <p>ロッカールーム</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.play_lot">
                        <p>プレイロット</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.aed">
                        <p>AED</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.vending_machine">
                        <p>自販機</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.outer_circumference">
                        <p>外回り</p>
                    </div>
                    <div class="item_flex"><input type="checkbox" v-model="receive_institution.collective_mailbox">
                        <p>集合郵便ポスト</p>
                    </div>
                    <button class="btn" @click="edit_property()">保存する</button>
                </div>
            </div>
        </div>

        <!-- この物件にある施設が、trueで表示、falseで非表示、コードをもっと簡略化させる方法を考えるv-for -->
        <div class="grid">
            <!-- <div v-for="(items,index) in institution" :key="items.item">
                <div v-if="flag = 'flag'.concat(index)">{{ items.item }}{{Boolean('flag'.concat(index))}}</div>
            </div> -->
            <div v-if="receive_institution.elevator">エレベーター</div>
            <div v-if="receive_institution.hallway">共用廊下</div>
            <div v-if="receive_institution.outside_stairs">外階段</div>
            <div v-if="receive_institution.planting">植栽</div>
            <div v-if="receive_institution.mechanical_parking">機械式駐車場</div>
            <div v-if="receive_institution.flat_parking">平置き駐車場</div>
            <div v-if="receive_institution.trash">ゴミ庫</div>
            <div v-if="receive_institution.trash_stocker">ゴミストッカー</div>
            <div v-if="receive_institution.entrance">エントランス</div>
            <div v-if="receive_institution.rack_b_parking">ラック式駐輪場</div>
            <div v-if="receive_institution.flat_b_parking">平置き駐輪場</div>
            <div v-if="receive_institution.flat_m_parking">平置きバイク置き場</div>
            <div v-if="receive_institution.board">掲示板・掲示物</div>
            <div v-if="receive_institution.mailbox">メールボックス</div>
            <div v-if="receive_institution.delivery_box">宅配ボックス</div>
            <div v-if="receive_institution.janitor">管理室</div>
            <div v-if="receive_institution.tools">清掃道具</div>
            <div v-if="receive_institution.receiving_tank">受水槽</div>
            <div v-if="receive_institution.cesspool">汚水槽</div>
            <div v-if="receive_institution.septic_tank">浄化槽</div>
            <div v-if="receive_institution.elevated_tank">高架水槽</div>
            <div v-if="receive_institution.booster_pump">増圧ポンプ</div>
            <div v-if="receive_institution.drainage_pump">排水ポンプ</div>
            <div v-if="receive_institution.firefight_equipment">消防設備</div>
            <div v-if="receive_institution.air_conditioner">空調設備</div>
            <div v-if="receive_institution.electrical_work">電気工作物</div>
            <div v-if="receive_institution.emergency_elevator">非常用エレベーター</div>
            <div v-if="receive_institution.disposer">ディスポーザー</div>
            <div v-if="receive_institution.distribution_board">分電盤</div>
            <div v-if="receive_institution.mdf_board">MDF盤</div>
            <div v-if="receive_institution.inner_staircase">内階段</div>
            <div v-if="receive_institution.inner_corridor">内廊下</div>
            <div v-if="receive_institution.rooftop">屋上</div>
            <div v-if="receive_institution.basement">地下室</div>
            <div v-if="receive_institution.store">店舗</div>
            <div v-if="receive_institution.office">事務所</div>
            <div v-if="receive_institution.kids_room">キッズルーム</div>
            <div v-if="receive_institution.assembly_room">集会室</div>
            <div v-if="receive_institution.locker_room">ロッカールーム</div>
            <div v-if="receive_institution.play_lot">プレイロット</div>
            <div v-if="receive_institution.aed">AED</div>
            <div v-if="receive_institution.vending_machine">自販機</div>
            <div v-if="receive_institution.outer_circumference">外回り</div>
            <div v-if="receive_institution.collective_mailbox">集合郵便ポスト</div>
        </div>
    </div>
</div>
</template>
<script scoped>
import { Mixin } from "../mixin"
import phone_head from "./phone_header.vue"

export default ({
    components: {
        phone_head
    },
    mixins: [Mixin],
    data() {
        return {
            receive_info: {},
            receive_institution: {},
            property_name: '',
            name: '',
            institution_items: [],
            message: "よろしいですか？",
            show_modal: false,
            authority_id: ''
        }
    },
    props: {
        msg: String,
    },
    methods: {
        open_modal() {
            this.show_modal = true;
        },
        async close_modal() {
            this.show_modal = false;
            const param = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token,
                },
                mode: 'cors'
            };
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/" + this.$route.query.property_id, param)
            if (res.status == 200) {
                var data = await res.json()
                this.receive_institution = data.property.institutions;
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
        },
        // 編集データの送信
        async edit_property() {
            const update_info = { info: this.receive_info, institutions: this.receive_institution };
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token,
                },
                body: JSON.stringify({ property: update_info }),
            };
            // 物件情報更新
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/update/" + this.$route.query.property_id, param)
            if (res.status == 200) {
                var data = await res.json()
                // console.log(data)
                location.reload();
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }

        }
    },
    created: async function () {
        this.authority_id = this.$store.state.authority_id
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token,
            },
            mode: 'cors'
        };
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/" + this.$route.query.property_id, param)
        if (res.status == 200) {
                var data = await res.json()
                // console.log(data);
                this.receive_info = data.property.info;
                this.receive_institution = data.property.institutions;
                this.property_id = data.property_id;
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
    }
})
</script>
<style scoped>
h2 {
    color: black;
}

.subtitle{
    margin-top: 0;
}

.property_name {
    margin: 0;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #1a3f66ff;
    color: #fff;
    font: caption;
    font-size: 18px;
    line-height: 1em;
    gap: 10px;
}

/* CSS */
#overlay {
    /* 要素を重ねた時の順番 */
    z-index: 9;

    /* 画面全体を覆う設定 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /* 画面の中央に要素を表示させる設定 */
    display: flex;
    align-items: center;
    justify-content: center;
}

#content {
    z-index: 10;
    overflow-y: auto;
    max-height: 95%;
    width: 90%;
    background: #fff;
    position: relative;
}

.modal_title_ {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 90%;
    height: 3%;
    margin: 0 auto;
    background-color: #EEEEEE;
    padding: 20px 0;
}

.modal_title_ a {
    width: 15%;
}

.modal_title_ h2 {
    width: 75%;
}

.modal_items {
    padding: 20px;
    margin-top: 15%;
}

.item_flex {
    display: flex;
}

.item_flex input {
    width: 20%;
}

.item_flex p {
    width: 80%;
    font-size: 20px;
}

.institution_list {
    position: relative;
}

.edit_btn {
    position: absolute;
    right: 30px;
    top: 3px;
}

.orange_btn {
    margin: 0;
    padding: 10px;
    vertical-align: top
}

.flex {
    display: flex;
    align-items: center;
    border: 1px solid grey;
}

.item {
    flex: 40%;
    height: 30px;
    border-right: 1px grey solid;
}

.item2 {
    flex: 60%;
}

.top_radius {
    border-radius: 5px 5px 0 0;
}

.bottom_radius {
    border-radius: 0 0 5px 5px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid div {
    height: 50px;
    border: 1px grey solid;
}

.btn {
    width: 120px;
    font-size: 18px;
    padding: 10px 18px;
    color: white;
    background-color: #1a3f66ff;
    border-radius: 10px;
}

#remarks{
    font-size: 18px;
    font-weight: bold;
}
</style>