import ImageUtil from "./imageUtil";

export const Mixin =  {
    data(){
      return{
        upper_photo_size : 10000000,      //画像サイズ上限
        compression_border : 500000,     //圧縮するかの基準,
        error_txt :  'エラーが発生しました。トップページに戻りますか？',
        error_size_txt : 'ファイルの上限サイズ10MBを超えています\n',
        error_type_txt : '.jpg、.gif、.png のいずれかのファイルのみ許可されています\n'
      }
    },  
    methods:{
        move_screen(path){
          this.$router.push(path)
        },
        flg_change(target){
          if(target){
            target = false
          }else{
            target = true
          }
          console.log(target)
        },
        unauth(){ 
          this.$store.commit('change_token',"")
          this.move_screen('/')
          return
        },
        phrase_check(institutions){
          let institution_array = Object.keys(institutions) 
          for(let i = 0 ; i < institution_array.length ; i++){
            for(let s = 0 ; s < institutions[institution_array[i]].items.length ; s++){
              if(institutions[institution_array[i]].items[s].phrase == '6'){
                return true
              }
            }
          }
          return false
        },
        other_error(error_num){
          let select = confirm(error_num + this.error_txt)
          if (select) {
              this.move_screen('/')
          }
          return
        },
        photo_blank_check(photo_array){
          for(let i = 0 ; i < photo_array.length ; i++){
              if(photo_array[i] === ''){
                  this.photo_blank_flg = false
              }
          }
        },
      // その他写真、共有事項写真

        // 写真の追加
        add_photo(event, input_target, input_target_name){
          // 既に登録されている写真の枚数
          var photo_len = input_target.length
          // 選択された写真の枚数
          var file_len = event.target.files.length

          // 画像の枚数が８枚より大きくなる場合
          if(photo_len + file_len >8){
            return window.alert('８枚までしか画像を登録できません');
          } 
          // 写真サイズ上限、タイプ判定
          for(var i = 0; i < event.target.files.length; i++){
            const file = event.target.files[i]
            const type = file.type
            if (file.size > this.upper_photo_size) {
              return window.alert(this.error_size_txt);
            }
            if (type != 'image/jpeg' && type != 'image/gif' && type != 'image/png'){
              return window.alert(this.error_type_txt)
            }
          }

          // 選択枚数分登録処理を行う
          for(var i = 0; i < event.target.files.length; i++){
            var index = photo_len + i
            this.input_base64(event.target.files[i], input_target, input_target_name, index, event)
          }

        },
        // 写真登録
        async input_base64(target_file, input_target, input_target_name, index, event) {

          // BASE64変換
          const reader = new FileReader()

          // 選択されたファイルの取得
          const file = target_file
          const size = file.size
          const type = file.type
          var errors = ''

          //上限サイズ判定
          if (size > this.upper_photo_size) {
              errors += this.error_size_txt
          }
          //拡張子は .jpg .gif .png のみ許可
          if (type != 'image/jpeg' && type != 'image/gif' && type != 'image/png') {
              errors += this.error_type_txt
          }

          if (errors) {
              //errorsが存在する場合は内容をalert
              alert(errors)
              //valueを空にしてリセットする
              event.currentTarget.value = ''
          } else {

              // ファイル名を”ミリ秒_index”
              input_target_name[index] = (+new Date()) + "_" + index;

              var nfile
              // console.log(size)
              if (size > this.compression_border) {
                  // 500KB超えていたら圧縮
                  nfile = await ImageUtil.getCompressImageFileAsync(file);
              } else {
                  nfile = file
              }
              // ファイルが選択されていればBase64に変換する
              if (nfile) {
                  reader.readAsDataURL(nfile)
              }

              // 変換が終わったら実行される
              reader.onload = () => {
                  input_target[index] = reader.result;
                  event.target.value = "";
              }
            }
        },
        // 写真削除
        delete_photo(delete_target, delete_target_name, index){
          delete_target.splice(index, 1)
          delete_target_name.splice(index, 1)
        },

        // 施設項目      
        // 写真追加
        add_institution_photo(event, institution){
          // 既に登録されている写真の枚数
          var item_len = institution.items.length
          // 選択された写真の枚数
          var file_len = event.target.files.length
          // 画像の枚数が８枚より大きくなる場合
        if(item_len + file_len >8){
          return window.alert('８枚までしか画像を登録できません');
        }
          // 写真サイズ上限判定
          for(var i = 0; i < event.target.files.length; i++){
            const file = event.target.files[i]
            const type = file.type
            if (file.size > this.upper_photo_size) {
              return window.alert(this.error_size_txt);
            }
            if (type != 'image/jpeg' && type != 'image/gif' && type != 'image/png'){
              return window.alert(this.error_type_txt)
            }
          }
        // 選択枚数分登録処理を行う
        for(var i = 0; i < file_len; i++){
          // 項目追加
          this.add_institution_item(institution)
          // 画像登録
          this.institution_input_base64(event.target.files[i], institution, item_len+i, event)
        }
        },
        // 写真登録
        async institution_input_base64(target_file, institution, index, event) {

          // BASE64変換
          const reader = new FileReader()
    
          // 選択されたファイルの取得
          const file = target_file
          const size = file.size
          const type = file.type
          var errors = ''
    
          //上限サイズ判定
          if (size > this.upper_photo_size) {
              errors += this.error_size_txt
          }
          //拡張子は .jpg .gif .png のみ許可
          if (type != 'image/jpeg' && type != 'image/gif' && type != 'image/png') {
              errors += this.error_type_txt
          }
    
          if (errors) {
              //errorsが存在する場合は内容をalert
              alert(errors)
              //valueを空にしてリセットする
              event.currentTarget.value = ''
          } else {
            // ファイル名を”ミリ秒_index”
            institution.items[index].photo_name = (+new Date()) + "_" + index;               
            var nfile
            // console.log(size)
            if (size > this.compression_border) {
                // 500KB超えていたら圧縮
                nfile = await ImageUtil.getCompressImageFileAsync(file);
                 
            } else {
                nfile = file
            }
            // ファイルが選択されていればBase64に変換する
            if (nfile) {
              reader.readAsDataURL(nfile)
            }
    
            // 変換が終わったら実行される
            reader.onload = () => {
              institution.items[index].photo = reader.result;
              event.target.value = "";
            }
          }
        },
        
        // 項目追加
        add_institution_item(institution){
          institution.items.push({
            photo: '',
            photo_name: '',
            select: true,
            phrase: '6',
            text: ''
            })

        },

        // 写真削除
        delete_institution_item(delete_target, index){
          delete_target.items.splice(index, 1)
        },

        // 報告書削除
        async report_delete(){
          var report_id = this.$route.query.property_id + this.$route.query.report_id
          const param = {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$store.state.login_token,
            },
            body: JSON.stringify({
              report_id: report_id,
            })
          };
          // 報告書情報更新
          const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/delete/" + this.$route.query.property_id + this.$route.query.report_id, param)
          if (res.status == 200) {
            alert('削除しました。\n復旧したい場合、システム事業部開発課に連絡してください。')
            this.$router.push('/pc/report/list')
          } else if (res.status == 401) {
            this.unauth()
          } else {
            this.other_error(res.status)
          }
        },

        // 前回の報告書情報取得
        async last_report_info(){
            // 前回の報告書IDを取得
            var past_report = this.$route.query.report_id - 1;
            console.log(past_report);
            // 前回の報告書IDが0の場合、前回の報告書は存在の場合はないので、フラグをfalseにする
            var past_report_id = ('000' + past_report).slice(-4);
            var pre_res, pre_data;
            const param = {
              method: "GET",
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.$store.state.login_token,
              }
            };
            //do-whileループを導入しました。ループの本体で報告書データを取得し、delete_flgがtrueであればループを続行します。
            do {
                if (past_report === 0) {
                    console.log("前回情報は存在しません。");
                    this.past_report_flag = false;
                    break;
                }
                pre_res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + past_report_id, param);
                if (pre_res.status == 200) {
                    pre_data = await pre_res.json();
                    // console.log(pre_data);
                    if (pre_data.report.delete_flg) {
                        past_report = past_report - 1;
                        past_report_id = ('000' + past_report).slice(-4);
                    } else {
                        this.past_institutions = pre_data.report.institutions;
                    }
                } else if (pre_res.status == 401) {
                    this.unauth();
                    return;
                } else {
                    this.other_error(pre_res.status);
                    return;
                }
            } while (pre_data.report.delete_flg);
        },
      }
  }