<template v-if="vflg == true">
    <main>
        <phone_head title="報告入力" :path="{ path: '/property/top/', query: { property_id: this.$route.query.property_id } }">
        </phone_head>
        <div class="wrapper">
            <div>
                日付: <input class="date" type="date" v-model="date" style="height:50px; width:150px" autofocus required /><br>
                担当者:{{ info.person }}<br>
                物件名:{{ info.name }}
            </div>
            <ul id="item_info" v-for="(institution, key) in institutions" :key="institution">
                <input_item_update :institution="institution" :past_institution="past_institutions[key]"
                    :past_report_check="past_report_flag">
                </input_item_update>
            </ul>
            <!-- その他写真 -->
            <div class="title">
                <h2>その他写真</h2>
                <button class="flg_btn" @click="flg_change('others_photo_flg')">{{ others_photo_flg_text }}</button>
            </div>
            <div v-if="others_photo_flg">
                <ul id="others_photo_items" class="center">
                    <li class='item' v-for="(photo, index) of others_photo" :key="photo">
                        <input type="file" ref="preview" @change="input_base64($event.target.files[0], this.others_photo, this.others_photo_name, index, $event)">
                        <img :src="photo">
                        <button class="delete_btn"  @click="delete_photo(others_photo, others_photo_name, index)">写真を消去します</button>
                    </li>
                </ul>
                <!-- その他写真、複数枚選択 -->
                <label v-if="others_photo.length < 8" class="file_label">
                    <input class="file_select" type="file" @change="add_photo($event, others_photo, others_photo_name)" multiple="multiple">写真を選択
                </label>
            </div>
            
            <!-- 共有事項 -->
            <div class="title margin">
                <h2>共有事項</h2>
                <button class="flg_btn" @click="flg_change('share_flg')">{{ share_flg_text }}</button>
            </div>
            <div v-if="share_flg">
                <ul id="share_items" class="center">
                    <li class="item" v-for="(photo, index) of share_photo" :key="photo">
                        <input type="file" ref="preview" @change="input_base64($event.target.files[0], this.share_photo, this.share_photo_name, index, $event)">
                        <img :src="photo">
                        <p v-if="photo">写真{{ index + 1 }}</p>
                        <button class="delete_btn" @click="delete_photo(share_photo, share_photo_name, index)">写真を消去します</button>
                    </li>
                </ul>

                <!-- 共有事項、複数枚選択 -->
                <label class="file_label" v-if="share_photo.length < 8">
                    <input class="file_select" type="file" @change="add_photo($event, share_photo, share_photo_name)" multiple="multiple">写真を選択
                </label>
                
                <textarea rows="5" v-model="share_text" placeholder="共有事項の内容を記述してください"
                    style="width: 90%;border: solid 1px grey ;" maxlength="1000"></textarea>
                <h3>社内向け共有事項</h3>
                <p class="font14">※こちらの内容は報告書には記載されません</p>
                <textarea rows="5" v-model="internal_memo" placeholder="社内向け共有事項を記述してください"
                    style="width: 90%;border: solid 1px grey ;" maxlength="1000"></textarea>
            </div>
            <!-- ボタンリスト -->
            <div id="btn_list">
                <button class="orange_btn" @click="add_report()">確定する</button>
                <button class="orange_btn" @click="this.$router.back()"
                    style="background-color: #1a3f66ff;">キャンセル</button>
            </div>
        </div>
    </main>
</template>
<script scoped>
import { Mixin } from "../mixin"
import input_item_update from "./report_input_component.vue"
import phone_head from "./phone_header.vue"
import { db } from "../db";
import Dexie from 'dexie'


export default ({
    mixins: [Mixin],
    components: {
        input_item_update,
        phone_head
    },
    data() {
        return {
            vflg: true,
            info: {},                       // 情報
            institutions: {},               // 物件情報
            past_institutions: {},          // 過去物件情報
            flg_btn_text: "＋",             //　ボタンフラグ
            others_photo: [],               //　その他写真
            others_photo_name: [],          //　その他写真名前
            others_photo_flg: false,        //　その他写真フラグ
            others_photo_flg_text: "＋",    //  その他写真フラグTXT
            share_photo: [],                // 共有写真
            share_photo_name: [],           // 共有写真名
            share_text: '',                 // 共有TXT
            share_flg: false,               // 共有フラグ
            share_flg_text: "＋",           // 共有フラグTXT
            today: "",                      // 今日？
            past_report_flag: true,
            date: "",
            photo_blank_flg: true
        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
            if (this[flg + '_text'] == "＋") {
                this[flg + '_text'] = "－";
            } else {
                this[flg + '_text'] = "＋";
            }
        },
        async add_report() {
            // 写真が選択されていない項目があるか確認
            this.photo_blank_flg = true
            // 項目が選択されていないものがあるか
            console.log(Object.keys(this.institutions).length)
            if(this.phrase_check(this.institutions)==true){
                return window.alert("未入力の選択文があります")
            }
            // その他写真確認
            this.photo_blank_check(this.others_photo)
            // 共有事項写真確認
            this.photo_blank_check(this.share_photo)
            if(this.date == ""){
                return window.alert("日付を入力してください")
            }
            if (this.photo_blank_flg) {
                db.delete()
                db.open()
                try {
                    // Put the report_info
                    await db.report_db.put({
                        info: Dexie.deepClone(this.info),
                        institutions: Dexie.deepClone(this.institutions),
                        others_photo: {
                            photo: Dexie.deepClone(this.others_photo),
                            photo_name: Dexie.deepClone(this.others_photo_name)
                        },
                        share: {
                            photo: Dexie.deepClone(this.share_photo),
                            photo_name: Dexie.deepClone(this.share_photo_name),
                            text: this.share_text,
                        },
                        internal_memo: this.internal_memo,
                        date: this.date
                    });
                    this.$router.push({ path: '/report/report/confirm/', query: { property_id: this.$route.query.property_id } });
                } catch (error) {
                    console.log(error)
                }
            } else {
                alert('写真が選択されていない項目があります')
            }
        },
    },
    created: async function () {
        var report_data = await db.report_db.get(1)
        // console.log(report_data)
        this.date = report_data.date
        this.share_photo = report_data.share.photo
        this.share_text = report_data.share.text
        this.internal_memo = report_data.internal_memo
        this.others_photo = report_data.others_photo.photo
        // その他写真データがあるのみデータを引き渡す
        if (report_data.others_photo.photo_name !== undefined && report_data.others_photo.photo_name.length > 0) {
            this.others_photo_name = report_data.others_photo.photo_name
        }
        // 共有事項写真データがあるのみデータを引き渡す
        if (report_data.share.photo_name !== undefined && report_data.share.photo_name.length > 0) {
            this.share_photo_name = report_data.share.photo_name
        }
        var institutions_flg = {}

        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // 物件情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/" + this.$route.query.property_id, param)
        if (res.status == 200) {
            var data = await res.json()
            // レスポンス情報の取得
            this.info = data.property.info
            // console.log(data)
            institutions_flg = data.property.institutions;
            // 前回情報の取得
            // 前回の報告書IDを取得
            var past_report = data.property.report_count;
            // 前回の報告書IDが0の場合、前回の報告書は存在の場合はないので、フラグをfalseにする
            if (past_report !== 0) {
                var past_report_id = ('000' + past_report).slice(-4);
                var pre_res, pre_data;
                //do-whileループを導入しました。ループの本体で報告書データを取得し、delete_flgがtrueであればループを続行します。
                do {
                    if (past_report === 0) {
                        console.log("前回情報は存在しません。");
                        this.past_report_flag = false;
                        break;
                    }
                    pre_res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + past_report_id, param);
                    if (pre_res.status == 200) {
                        pre_data = await pre_res.json();
                        // console.log(pre_data);
                        if (pre_data.report.delete_flg) {
                            past_report = past_report - 1;
                            past_report_id = ('000' + past_report).slice(-4);
                        } else {
                            this.past_institutions = pre_data.report.institutions;
                        }
                    } else if (pre_res.status == 401) {
                        this.unauth();
                        return;
                    } else {
                        this.other_error(pre_res.status);
                        return;
                    }
                } while (pre_data.report.delete_flg);
            } else {
                console.log("前回情報は存在しません。");
                this.past_report_flag = false;
            }
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
        for (let institution in report_data.institutions) {
            // 施設有無情報を元に項目を選定する。
            if (institutions_flg[institution]) {
                this.institutions[institution] = report_data.institutions[institution]
            }
        }

    },
})
</script>

<style scoped>
img {
    width: 100%;
}

.orange_btn {
    width: 40%;
    margin: 10px 0 10px 5px;

}

.wrapper {
    width: 90%;
}

h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
}

.flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.font14 {
    font-size: 14px;
}

.margin {
    margin-top: 15px;
}

.center {
    text-align: center;
}

.item {
    text-align: center;
    border: solid 3px #1a3f66ff;
    border-radius: 10px;
    margin-top: 10px;
}

.delete_btn {
    background-color: #1a3f66ff;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
}

#btn_list {
    margin-top: 50px;
}

input {
    color: #FFFFFF;
}

.date {
    color: black;
}

.file_select {
    display: none;
}

.file_label {
    display: inline-block;
    padding: 10px 5px;
    margin-bottom: 10px;
    color: black;
    background-color: lightgray;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
}
</style>